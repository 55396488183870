import { useSelector } from 'react-redux'


export const useLang = () => {
    const lang = useSelector((state) => state.lang)
    return lang
}  
export const useAuth = () => {
    const auth = useSelector((state) => state.auth)
    return auth
}  
export const useAssistans = () => {
    const assistants = useSelector((state) => state.assistants)
    return assistants
}  