import React, { useState } from "react";

const MultipleFileInputComponent = ({
  name = "",
  defVal = {},
  disabled = false,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const editFiles = JSON.parse(defVal?.attachments || "[]");
  const handleFileChange = (event) => {
    const files = event.target.files;
    const selectedFilesArray = [];

    // Convert FileList to array
    for (let i = 0; i < files.length; i++) {
      selectedFilesArray.push(files[i]);
    }
    setSelectedFiles(selectedFilesArray);
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
  };

  const handleFilePreview = (file) => {
    const fileURL = URL.createObjectURL(file);

    // Open the URL in a new tab to prompt the browser to handle it
    window.open(fileURL);
  };

  return (
    <div className="bg-[#F1F2FD80] border-dashed min-h-[170px] flex-col gap-[5px] flex justify-center items-center p-[10px] border-[#535CE8] border-[2px] rounded-[10px]">
      <label
        htmlFor={name}
        className="p-[3px] px-[8px] border-[1px] rounded-[5px] border-[#535de8] text-[#535CE8]"
      >
        <input
          type="file"
          name={name}
          disabled={disabled}
          id={name}
          onChange={handleFileChange}
          multiple
          className="hidden"
        />
        Добавить файл
      </label>
      {editFiles?.length < 1 && selectedFiles.length < 1 && <p>Файл не выбран</p>}
      {selectedFiles.length > 0 && (
        <div>
          <ul className="flex items-center gap-2 flex-wrap mt-[14px]">
            {selectedFiles?.map((file, index) => (
              <li
                key={index}
                className="p-[3px] px-[8px] border-[2px] rounded-[2px] border-[#79896C]"
              >
                <button type="button" onClick={() => handleFilePreview(file)}>
                  {file.name.slice(0, 23)}...{" "}
                </button>
                <button onClick={() => handleRemoveFile(file)} type="button">
                  &times;
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {editFiles?.length > 0 && selectedFiles.length < 1 ? (
        <div>
          <ul className="flex items-center gap-2 flex-wrap mt-[14px]">
            {editFiles?.map((file, index) => (
              <li
                key={index}
                className="p-[3px] px-[8px] border-[2px] rounded-[2px] border-[#79896C]"
              >
                <a
                  target="_blank"
                  href={"https://api.sfinx.remzilab.com/files/" + file}
                >
                  {file.slice(0, 23)}...{" "}
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MultipleFileInputComponent;
