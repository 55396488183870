import React from "react";
import { useDispatch } from "react-redux";
import { postRequest } from "../utils/request";
import { useParams } from "react-router-dom";
import MultipleFileInputComponent from "./FilePicker";

function AddTask({ closeModal, getData }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  function formSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    postRequest("/manager/task", formData, dispatch)
      .then((res) => {
        console.log(res);
        getData();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="fixed h-screen w-full left-0 top-0 backdrop-blur-[1px] bg-[#00000055]  z-[99]">
      <div className="bg-[white] w-full max-w-[904px] p-[30px] absolute h-screen overflow-auto top-0 right-0 res:pb-[120px] res:pb-[100px]">
        <button
          onClick={closeModal}
          className="absolute right-[20px] text-[24px] top-[10px]"
        >
          &times;
        </button>
        <h1 className="text-[32px] LatoBold text-left font-[700] mb-[20px]">
          Добавить задание
        </h1>
        <form onSubmit={formSubmit}>
          <div className="grid gap-[20px] uppercase">
            <div className="w-[40%] min-w-[270px]">
              <div className="grid grid-cols-2 gap-[10px]">
                <div className="flex items-center gap-[8px]">
                  <img src={require("../assets/images/calendar.png")} alt="" />
                  <p className="text-[14px] opacity-55">Начало</p>
                </div>
                <input
                  type="date"
                  name="start_date"
                  className="bg-[#F3F4F6] px-[10px] py-[5px] rounded-full p-[10px] outline-none "
                />
                <div className="flex items-center gap-[8px]">
                  <img src={require("../assets/images/calendar.png")} alt="" />
                  <p className="text-[14px] opacity-55">Конец</p>
                </div>
                <input
                  type="date"
                  name="end_date"
                  className="bg-[#F3F4F6] px-[10px] py-[5px] rounded-full p-[10px] outline-none "
                />

                <div className="flex items-center gap-[8px]">
                  <img src={require("../assets/images/status.png")} alt="" />
                  <p className="text-[14px] opacity-55">Status</p>
                </div>
                <select
                  className="bg-[#F3F4F6] px-[10px] py-[5px] rounded-full p-[10px] outline-none "
                  name="status"
                >
                  <option value="0">TO DO</option>
                  <option value="1">PENDING</option>
                  <option value="2">BLOCKED</option>
                  <option value="3">DONE</option>
                </select>
              </div>
            </div>

            <div className="grid  gap-[10px] items-start">
              <p className="text-[16px] uppercase font-[700] outfit">
                Название
              </p>
              <textarea
                name="name"
                className="bg-[#F3F4F6] rounded-[5px] p-[10px] outline-none "
                id=""
                placeholder="Введите название"
                rows="1"
              ></textarea>
              <input
                type="text"
                name="user_id"
                defaultValue={id}
                className="hidden"
              />
            </div>

            <div className="grid  gap-[10px] items-start">
              <p className="text-[16px] uppercase font-[700] outfit">
                Oтветственный
              </p>
              <textarea
                name="responsible"
                className="bg-[#F3F4F6] rounded-[5px] p-[10px] outline-none "
                rows="1"
              ></textarea>
            </div>
            <div className="grid  gap-[10px] items-start pb-[20px] border-b">
              <p className="text-[16px] uppercase font-[700] outfit">
                Описание
              </p>
              <textarea
                name="description"
                className="bg-[#F3F4F6] rounded-[5px] p-[10px] outline-none "
                rows="1"
              ></textarea>
            </div>
            <div className="grid  gap-[10px] items-start ">
              <p className="text-[16px] uppercase font-[700] outfit">
                Документы
              </p>
              <MultipleFileInputComponent name="attachments[]" />
            </div>
            <div className="flex justify-end pt-[20px] border-t gap-[30px] items-center">
              <button onClick={closeModal} type="button">
                Отмена
              </button>
              <button className=" p-2 min-w-[170px]  rounded-[5px] text-white py-[10px] px-[20px] bg-[#535CE8] ">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddTask;
