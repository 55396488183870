import { createSlice } from '@reduxjs/toolkit'
const langs = {
    ru: 'ru',
    en: 'en',
}
const initialState = {
    lang: langs.ru,
    langType: 'ru',
}

export const langSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            state.lang = langs[action.payload]
            state.langType = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeLanguage } = langSlice.actions

export default langSlice.reducer