import React from "react";
import { postRequest } from "../utils/request";
import { useDispatch } from "react-redux";
import DirecSelect from "./DirecSelect";
import InputMask from "react-input-mask";

function AddClientModal({ closeModal, isEdit, id, getData, data = {} }) {
  const dispatch = useDispatch();

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  function formSubmit(e) {
    const path = isEdit
      ? `/manager/client-board/update/${id}`
      : "/manager/client-board";
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    postRequest(path, data, dispatch)
      .then((res) => {
        console.log(res);
        if (res.success) {
          closeModal();
          getData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="fixed bg-[#00000055] h-screen w-full left-0 top-0 backdrop-blur-sm  z-[99] flex justify-center items-center overflow-auto pt-[50px]">
      <div className="bg-[#fff] w-full max-w-[954px] p-[30px] absolute top-0 right-0 h-screen overflow-auto res:pb-[120px]">
        <button
          onClick={closeModal}
          className="absolute right-[20px] text-[24px] top-[10px]"
        >
          &times;
        </button>
        <h1 className="text-[24px] mb-[20px] LatoBold">
          {isEdit ? "Редактировать" : "Создать"} доску клиентов
        </h1>
        <form onSubmit={formSubmit}>
          <div className="grid gap-[20px] uppercase">
            <div className="grid gap-[5px] text-left">
              <p className="outfit font-semibold text-[16px]">Имя влодельца</p>
              <input
                type="text"
                defaultValue={data?.owner_name}
                name="owner_name"
                className="p-[10px] bg-[#F3F4F6] rounded-[5px] px-[15px] outline-none "
              />
            </div>

            <div className="grid gap-[5px] text-left">
              <p className="outfit font-semibold text-[16px]">
                Название проекта
              </p>
              <input
                type="text"
                name="project_name"
                defaultValue={data?.project_name}
                className="p-[10px] bg-[#F3F4F6] rounded-[5px] px-[15px] outline-none "
              />
            </div>
            <div className="grid gap-[5px] text-left">
              <p className="outfit font-semibold text-[16px]">Направление</p>

              <DirecSelect />
            </div>
            <div className="grid gap-[5px] text-left">
              <p className="outfit font-semibold text-[16px]">Номер телефона</p>
              <InputMask
                className="p-[10px] bg-[#F3F4F6] rounded-[5px] px-[15px] outline-none "
                placeholder="Ваш телефон *"
                mask="+7 (999) 999-99-99"
                name="phone"
                defaultValue={data?.phone}
                maskChar=" "
              />
            </div>
            <div className="grid gap-[5px] text-left">
              <p className="outfit font-semibold text-[16px]">Мессенжер</p>
              <select
                name="messanger"
                defaultValue={data?.messanger}
                className="p-[10px] bg-[#F3F4F6] rounded-[5px] px-[15px] outline-none "
                id=""
              >
                <option value="WhatsApp">
                  WhatsApp
                </option>
                <option value="Facebook">
                  Facebook
                </option>
                <option value="Messenger">
                  Messenger
                </option>
                <option value="SnapChat">
                  SnapChat
                </option>
                <option value="WeChat">
                  WeChat
                </option>
                <option value="Viber">
                  Viber
                </option>
                <option value="Telegram">
                  Telegram
                </option>
              </select>
            </div>
            <div className="grid gap-[5px] text-left">
              <p className="outfit font-semibold text-[16px]">
                Социальный адрес
              </p>

              <input
                type="text"
                name="social_address"
                defaultValue={data?.social_address}
                className="p-[10px] bg-[#F3F4F6] rounded-[5px] px-[15px] outline-none "
              />
            </div>
            <div className="grid gap-[5px] text-left">
              <p className="outfit font-semibold text-[16px]">Страна</p>
              <select
                name="country"
                defaultValue={data?.country}
                className="p-[10px] bg-[#F3F4F6] rounded-[5px] px-[15px] outline-none "
                id=""
              >
                {countries.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>

            <div className="grid gap-[5px] text-left">
              <p className="outfit font-semibold text-[16px]">Логин</p>
              <input
                type="text"
                name="email"
                defaultValue={data?.email}
                className="p-[10px] bg-[#F3F4F6] rounded-[5px] px-[15px] outline-none "
              />
            </div>

            <div className="grid gap-[5px] text-left">
              <p className="outfit font-semibold text-[16px]">Пароль</p>
              <input
                type="text"
                name="password"
                className="p-[10px] bg-[#F3F4F6] rounded-[5px] px-[15px] outline-none "
              />
            </div>
            <div className="flex justify-end pt-[20px] border-t gap-[30px] items-center">
              <button onClick={closeModal} type="button">
                Отмена
              </button>
              <button className=" p-2 min-w-[170px]  rounded-[5px] text-white py-[10px] px-[20px] bg-[#535CE8] ">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddClientModal;
