import React, { useEffect, useState } from "react";
import AddClientModal from "./AddClientModal";
import Wrapper from "./Wrapper";
import { getRequest } from "../utils/request";
import { useDispatch } from "react-redux";
import { useAuth } from "../redux/selectors";
import { Link, useNavigate } from "react-router-dom";
import proSVG from "../assets/images/pro.svg";

function Create() {
  const [page, setPage] = useState(1);
  function getData() {
    getRequest("/manager/client-board/list/" + page, dispatch)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const [addModal, setAddModal] = useState(false);
  const [data, setData] = useState([]);
  function RowTab({ items }) {
    const [isModal, setIsModal] = useState(false);
    const [show, setShow] = useState(false);
    return (
      <>
        <div className="grid res:hidden grid-cols-10 text-[13px] text-center border-b p-[20px] py-[20px] whitespace-nowrap items-center">
          <p className="autfit font-bold">{items?.id}</p>
          <p className="autfit font-bold">{items?.owner_name}</p>
          <p className="autfit opacity-45">{items?.created_at.slice(0, 10)}</p>
          <p>{items?.project_name}</p>
          <p className="w-full overflow-hidden text-ellipsis">
            {items?.directions}
          </p>
          <p>{items?.phone}</p>
          <p>{items?.messanger}</p>
          <p>{items?.country}</p>
          <div className="flex gap-3 relative right-[-10px]">
            <p className="w-full overflow-hidden text-ellipsis">
              {items?.email}
            </p>
          </div>
          <div>
            <button
              className="bg-[#F1F8FD] rounded-full p-[10px] text-[#379AE6] py-[5px]"
              onClick={() => setIsModal(true)}
            >
              Редактировать
            </button>
          </div>
        </div>
        {isModal && (
          <AddClientModal
            closeModal={() => setIsModal(false)}
            isEdit={true}
            id={items?.id}
            data={items}
            getData={getData}
          />
        )}
        <div className="py-[20px] border-b hidden res:block">
          <div
            onClick={() => setShow(!show)}
            className="flex justify-between cursor-pointer items-center"
          >
            <p className="text-[16px] font-[700]">ID</p>
            <p className="text-[16px] font-[700]">{items?.id}</p>
            <button
              style={{
                transform: !show ? "rotate(180deg)" : "",
              }}
            >
              <img src={require("../assets/images/arrow.png")} alt="" />
            </button>
          </div>
          {show && (
            <div className="grid grid-cols-2">
              <div className="grid">
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  Имя Владельца
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  DATE
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  Название проекта
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  Направления
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  Номер телефона
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  Мессенжер
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  Cтрана
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  Логин
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden font-[700] text-ellipsis whitespace-nowrap">
                  Действия
                </p>
              </div>
              <div className="grid">
                <p className="py-[10px] text-[14px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {items?.owner_name}
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {items?.created_at.slice(0, 10)}
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {items?.project_name}
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {items?.directions}
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {items?.phone}
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {items?.messanger}
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {items?.country}
                </p>
                <p className="py-[10px] text-[14px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  {items?.email}
                </p>
                <p className="py-[4px] w-full overflow-hidden text-ellipsis whitespace-nowrap">
                  <button
                    className="bg-[#F1F8FD] rounded-full p-[10px] text-[#379AE6] py-[5px]"
                    onClick={() => setIsModal(true)}
                  >
                    Редактировать
                  </button>
                </p>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
  const { user } = useAuth();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  useEffect(() => {
    if(user?.roles?.[0]?.name === 'support'){
      navigate('/board')
    }
    getData();
  }, [page]);

  return (
    <Wrapper>
      <nav className="border-b border-[#171A1F26]">
        <div className="max-w-[1920px] flex items-center justify-between mx-auto my-[20px] px-[36px]">
          <Link to={"/board"}>
            <button className="  flex gap-3 items-center">
              <img src={proSVG} alt="" />
              <p className="opacity-70">Доска прогресса</p>
            </button>
          </Link>
          <div className="flex justify-end items-center">
            <div className="flex gap-3 items-center">
              <img
                src={"https://cdn-icons-png.flaticon.com/512/9187/9187604.png"}
                className="w-[40px] h-[40px] rounded-full bg-[#ffffff] res:hidden"
                alt=""
              />
              <div className="grid res:hidden">
                <p className="text-[16px]">{user?.owner_name}</p>
                <p className="text-[10px]">{user?.email}</p>
              </div>

              <Link to={"/login"}>
                <button>
                  <img
                    src={require("../assets/images/logout.png")}
                    className="ml-[30px]"
                    alt=""
                  />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <div className="px-[36px] res:px-[24px] max-w-[1920px] py-[20px] mx-auto overflow-auto">
        <div className="py-[20px]">
          <div className="flex justify-end res:justify-start">
            <button
              onClick={() => setAddModal(true)}
              className=" p-2 min-w-[170px] res:text-[14px] res:p-[5px_10px]  rounded-[5px] text-white px-[20px] bg-[#535CE8] mb-[20px]"
            >
              + Создать доску клиентов
            </button>
            {addModal && (
              <AddClientModal
                getData={getData}
                closeModal={() => setAddModal(false)}
              />
            )}
          </div>
          <table className="border res:border-none w-full">
            <div className="grid res:hidden grid-cols-10 text-[13px] border-b text-center p-[20px] bg-[#FAFAFB]">
              <p className="outfit font-bold">ID</p>
              <p className="outfit font-bold">Имя Владельца</p>
              <p className="outfit font-bold">DATE</p>
              <p className="outfit font-bold">Название проекта</p>
              <p className="outfit font-bold">Направления</p>
              <p className="outfit font-bold">Номер телефона</p>
              <p className="outfit font-bold">Мессенжер</p>
              <p className="outfit font-bold">Cтрана</p>
              <p className="outfit font-bold">Логин</p>
              <p className="outfit font-bold">Действия</p>
            </div>
            {data?.data?.data?.map((item, index) => (
              <RowTab key={index} items={item} />
            ))}
          </table>
          <div className="flex justify-center gap-2 my-2 mt-[40px]">
            <button
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1);
                }
              }}
              className="border border-[#9095A0] p-[10px] text-[#9095A0]"
            >
              Назад
            </button>
            <button className="border border-[#9095A0] p-[10px] text-[#9095A0]">
              {data?.data?.last_page}/{data?.data?.current_page}
            </button>
            <button
              onClick={() => {
                if (data?.data?.last_page !== data?.data?.current_page) {
                  setPage(page + 1);
                }
              }}
              className="border border-[#9095A0] p-[10px] text-[#9095A0]"
            >
              Вперед
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Create;
