import React, { useState } from "react";

const DirecSelect = () => {
  const options = [
    {
      name: "Сайты",
      children: [
        "Лендинг",
        "Сайт-визитка",
        "Корпоративный информационный веб-сайт",
        "Корпоративный эмиджевый веб-сайт",
        "Информационный сайт",
        "Игровой портал",
        "Персональный проект",
        "Сайт каталог",
        "Промо-сайт",
        "Сайт-форум",
        "Новостные сайты",
        "Блог",
        "Тематический сайт",
        "Другое",
      ],
    },
    {
      name: "Веб приложения",
      children: [
        "CRM-система",
        "ERP-система",
        "Онлайн-сервис",
        "Интернет-магазин",
        "Социальная сеть",
        "Веб-сервис",
        "Персональный сервис",
        "Вики-сайт",
        "Веб-бот",
        "Веб-электронная коммерция",
        "e-commerce",
        "Образовательные ресурсы",
        "Поисковые системы",
        "Агрегатор",
        "Доска объявлений",
        "Личные кабинеты",
        "Другое",
      ],
    },
    {
      name: "Мобил-проложения",
      children: ["Нативный", "Кросс"],
    },
    {
      name: "Автомтаизазия",
      children: [
        "Веб-автоматизации",
        "Моб-автоматизации",
        "Desktop-автоматизации",
      ],
    },
    {
      name: "Чат-бот",
      children: [
        "Telegram",
        "WhatsApp-Business",
        "Популярные чат выджеты",
        "Голосовой канал",
      ],
    },
    {
      name: "Интеграция и автоматизации",
      children: [
        "Типовое внедрение решений",
        "Индивидуальная автоматизация",
        "Интеграция IT-решений и сервисов",
      ],
    },
    {
      name: "Внедрите AI",
      children: [
        "Консультации по внедрению AI",
        "Разработка AI-решений",
        "Развитие AI-решений",
      ],
    },
    {
      name: "Системный анализ",
      children: [
        "Frontend-разработка",
        "Backend-разработка",
        "Мобильная разработка",
        "Quality Assurance",
        "DevOps",
      ],
    },
    {
      name: "UI & UX",
      children: [
        "Motion & Sound",
        "2D, 3D-иллюстрация",
        "Брендинг",
        "Графический дизайн",
        "UX-исследования",
      ],
    },
    {
      name: "Digital-маркетинга",
      children: [
        "Поисковая оптимизация (SEO)",
        "Контент-маркетинг",
        "Influencer-маркетинг",
        "Онлайн-PR",
        "Мобильное приложение",
        "Контекстная реклама",
        "Голосовая реклама",
        "Продвижение в мессенджерах",
        "Email-маркетинг",
        "SMM",
      ],
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      {/* Dropdown button */}
      <p
        onClick={toggleDropdown}
        type="button"
        className="p-[10px] bg-[#F3F4F6] rounded-[5px] px-[15px] outline-none flex justify-between items-center"
        // className="border-[#9095A0] pr-[8px] border cursor-pointer p-[10px] bg-transparent outline-none w-full text-left flex justify-between items-center"
      >
        {selectedOption ? selectedOption : "Выберите услугу"}
        <div className="w-[2px] h-[2px] border-2 border-black p-[2px] rotate-45 border-t-0 border-l-0"></div>
      </p>
      <input
        type="text"
        className="hidden"
        name="directions"
        value={selectedOption || ""}
      />
      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute mt-1 w-full bg-[#e8e8e8] rounded shadow-lg border-[#9095A0] border p-[10px] h-[400px] overflow-auto">
          {options.map((option) => (
            <div className="text-left">
              <h1 className="font-semibold">{option.name}</h1>
              {option.children.map((child) => (
                <p
                  onClick={() => handleOptionClick(child)}
                  className="cursor-pointer opacity-80 hover:bg-[#9095A0] text-[12px] hover:  p-[10px] text-left"
                >
                  {child}
                </p>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DirecSelect;
