import React, { useState } from "react";
import { postRequest } from "../utils/request";
import { useDispatch } from "react-redux";

function AddColumn({ closeModal, getData, projectId, allCols }) {
  const dispatch = useDispatch();
  function formSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {
      user_id: projectId,
    };
    formData.forEach((value, key) => {
      data[key] = value;
    });
    postRequest("/manager/column", data, dispatch)
      .then((res) => {
        console.log(res);
        getData();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function delCol(_id) {
    postRequest("/manager/column/delete/" + _id, {}, dispatch)
      .then((res) => {
        getData();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="fixed h-screen w-full bg-[#00000072] left-0 top-0 backdrop-blur-[2px]  z-[99] flex justify-center items-center">
      <div className="bg-[white] border w-full max-w-[904px] p-[30px] absolute h-screen right-0 overflow-auto">
        <button
          onClick={closeModal}
          className="absolute right-[20px] text-[24px] top-[10px]"
        >
          &times;
        </button>
        <h1 className="text-[32px] mb-[20px] outfit text-left font-[700]">
          Добавить столбец
        </h1>
        <p className="outfit text-[16px] font-[700] mb-[15px]">
          Название столбца
        </p>
        {allCols &&
          allCols?.map((item, index) => (
            <div key={index}>
              <div className="grid gap-[20px] items-center mb-[15px]">
                <div className="flex w-full gap-[10px]">
                  <input
                    type="text"
                    name="title"
                    placeholder="Введите название"
                    defaultValue={item?.title}
                    disabled
                    className=" bg-[#F3F4F6]  p-[10px] rounded-[5px] outline-none w-full"
                  />
                  <button
                    onClick={() => delCol(item?.id)}
                    className=" bg-[#F3F4F6]  p-[10px] rounded-[5px]"
                  >
                    <img
                      src={require("../assets/images/trash-red.png")}
                      className="w-[20px] mx-auto"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
          ))}

        <form onSubmit={formSubmit}>
          <div className="grid gap-[20px]">
            <div className="grid gap-[20px] items-center">
              <div className="flex w-full gap-[10px]">
                <input
                  type="text"
                  name="title"
                  placeholder="Введите название"
                  className=" bg-[#F3F4F6]  p-[10px] rounded-[5px] outline-none w-full"
                />
                <button
                  type="reset"
                  className=" bg-[#F3F4F6]  p-[10px] rounded-[5px]"
                >
                  <img
                    src={require("../assets/images/trash-red.png")}
                    className="w-[20px] mx-auto"
                    alt=""
                  />
                </button>
              </div>
            </div>
            <button className=" p-2 max-w-[170px]  rounded-[5px] text-white bg-[#535CE8] mb-[20px] outline-none">
              + Добавить еще
            </button>

            <div className="flex justify-end pt-[20px] border-t gap-[30px] items-center">
              <button onClick={closeModal} type="button">
                Отмена
              </button>
              <button className=" p-2 min-w-[170px]  rounded-[5px] text-white py-[10px] px-[20px] bg-[#535CE8] ">
                Сохранить
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddColumn;
