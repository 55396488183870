export const ToTopArrow = ({ color = "#DE3B40" }) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 13.7142L8 2.28564"
      stroke={color}
      strokeWidth="1.37143"
      strokeMiterlimit={10}
    />
    <path
      d="M4 6.28564L8 2.28564L12 6.28564"
      stroke={color}
      strokeWidth="1.37143"
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
);
