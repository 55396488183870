import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Board from "./components/Board";
import Create from "./components/Create";
import MyTask from "./components/MyTask";
import Login from "./components/Login";
import { useAssistans, useAuth } from "./redux/selectors";
import CheckLogin from "./components/CheckLogin";
import UserHome from "./components/UserHome";
import UserTask from "./components/UserTask";
import CreateAll from "./components/CreateAll";

function App() {
  const { isLoader } = useAssistans();
  const { user } = useAuth();
  const role = user?.roles?.[0]?.name;
  const sides = {
    manager: <Board />,
    support: <Board />,
    user: <UserHome />,
    superAdmin: <></>,
  };
  const tasks = {
    manager: <MyTask />,
    support: <MyTask />,
    user: <UserTask />,
    superAdmin: <></>,
  };
  const intro = {
    manager: <Create />,
    support: <Create />,
    user: <UserHome />,
    superAdmin: <CreateAll />,
  };
  return (
    <div className="App">
      {/* <Board />
        <Create /> */}
      {isLoader && (
        <div className="loader-wrapper">
          <span className="loader"></span>
        </div>
      )}
      <BrowserRouter>
        <CheckLogin />
        <Routes>
          <Route path="/board" element={sides[role]} />
          <Route path="/login" element={<Login />} />
          <Route path="/my-task/:id" element={tasks[role]} />
          <Route path="/" element={intro[role]} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
