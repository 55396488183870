import React, { useEffect } from "react";
import { getRequest } from "../utils/request";
import { useDispatch } from "react-redux";
import { authChange, setUser } from "../redux/slices/auth";
import { useLocation, useNavigate } from "react-router-dom";
function CheckLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    getRequest("/user", dispatch)
      .then((res) => {
        if (!res.message) {
          dispatch(authChange(true));
          dispatch(setUser(res));
          console.log("edited------------------------");
        } else {
          navigate("/login");
        }
      })
      .catch((err) => {
        navigate("/login");
      });
    getRequest("/techhub/config", dispatch)
      .then((res) => {
        // eslint-disable-next-line array-callback-return
        res?.data?.map((item) => {
          localStorage.setItem(item?.key, item?.value);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [window.location.pathname]);
  return <></>;
}

export default CheckLogin;
