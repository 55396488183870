import React from "react";
import { useAuth } from "../redux/selectors";

function Wrapper({ children }) {
  const { user } = useAuth();
  return (
    <div className=" min-h-[100vh] relative res:pb-[120px]">
      <div>{children}</div>
      <div className="hidden res:flex border-t fixed p-[20px] w-full gap-[15px] justify-center bottom-0 bg-white">
        <img
          src={"https://cdn-icons-png.flaticon.com/512/9187/9187604.png"}
          className="w-[40px] h-[40px] rounded-full bg-[#ffffff]"
          alt=""
        />
        <div className="grid">
          <p className="text-[16px]">{user?.owner_name}</p>
          <p className="text-[14px] opacity-60">{user?.email}</p>
        </div>
      </div>
    </div>
  );
}

export default Wrapper;
